import styled from 'styled-components';
import { device } from '../../utils';

export const Wrapper = styled.section`
  margin: 30px 0;

  .items {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .eshop-link {
    margin: 30px 0;
    text-align: center;
  }

  @media ${device.tablet} {
    .items {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media ${device.laptop} {
    margin: 70px 0;
  }
`;
