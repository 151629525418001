import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { useLocation } from '@reach/router';
import { useIntl } from 'react-intl';
import { navigate } from 'gatsby';

import { StoreContext } from '../../../services/shopify';
import Gallery from './Gallery';
import { ProductRecommendations } from '../../../components/eshop';
import { EshopBreadcrumb } from '../../../components/common';

import Description from './Description';
import MoreInfos from './MoreInfos';
import ContentBlock from './ContentBlock';
import FeedbacksBlock from './feedbacks';

import MIFIcon from '../../../images/eshop/madeinfr.png';
import { Icon } from '../../../components/icons';
import OfficialStoreIcon from '../../../icons/OfficialStore.svg';
import { trackAddToCart } from '../../../utils/gtm';
import { getProductUrl, isClient } from '../../../utils/';

import trads from '../trads';

import {
  Wrapper,
  TwoColumnGrid,
  GridLeft,
  GridRight,
  StickyBlock,
  MIF,
  Header,
} from './Product.styles';

const cleanUrlParams = (search) => {
  const strip = `${decodeURI(search)}`.split('?').pop();
  if (!strip) {
    return strip;
  }

  return strip.split('&').reduce((acc, key) => {
    const parts = key.split('=');
    acc[parts[0]] = parts[1];
    return acc;
  }, {});
}

const getMatchedVariant = (orgVariants, params, viscosity) => {
  const elected = orgVariants
  .filter(v => viscosity ? viscosity === v.viscosity : true)
  .find(v => v.selectedOptions.every(o => {
    const optionAsked = params[o.name];
    if (!optionAsked) {
      return true;
    }
    return optionAsked && optionAsked === o.value;
  }));

  if (elected) {
    return elected;
  }

  return orgVariants[0];
}

const ProductPage = (props) => {
  const { product } = props;
  const orgVariants = product.variants;
  const intl = useIntl();
  const location = useLocation();
  const urlParams = cleanUrlParams(location.search);
  const firstVariant = getMatchedVariant(orgVariants, urlParams, product.defaultVariant?.viscosity);

  const shopify = useContext(StoreContext);
  const [state, setState] = useState({
    variants: orgVariants,
    variantId: firstVariant ? firstVariant.shopifyId : null,
  });

  // const productId = product?.shopifyId;
  const variantId = state.variantId;
  const apiAddToCart = shopify.addToCart;
  // const apiFetchProduct = shopify.fetchProduct;
  const variants = state.variants;
  const variant = variants.find(v => v.shopifyId ===  variantId);
  const variantSku = variant?.sku;

  const addToCart = useCallback((variant, quantity) => {
    apiAddToCart(variant.shopifyId, quantity);
    trackAddToCart({
      name: product.title,
      category: product.productType,
      id: product.shopifyId,
      variant: variant.title,
      price: variant.price,
      quantity,
    });
  }, [apiAddToCart, product]);


  const selectOption = useCallback((option, value) => {
    const othersOptions = variant.selectedOptions.filter(o => o.name !== option.name);

    // Find available variants
    const variantStack = variants
      .filter(v => {
        return v.selectedOptions.find(o => o.name === option.name && o.value === value)
      })
      .map(v => ({
        shopifyId: v.shopifyId,
        options: v.selectedOptions.filter(o => o.name !== option.name),
        title: v.title
      }));
    
    let newVariant = variantStack.find(v => {
      return v.options.every(o => othersOptions.find(c => c.name === o.name && c.value === o.value))
    });

    if (!newVariant) {
      // If no match, pick the first
      newVariant = variantStack[0];
    }

    if (!newVariant) {
      return;
    }

    if (option.isViscosity) {
      navigate(getProductUrl(product.handle, intl.locale, {
        viscosity: value
      }), {
        replace: true
      });
    } else {
       setState(prev => ({
        ...prev,
        variantId: newVariant.shopifyId
      }));
    }

    // setState(prev => ({
    //   ...prev,
    //   variantId: newVariant.shopifyId
    // }));
  }, [variants, variant, product, intl.locale, navigate]);

  // console.log('[Product] Render', variant);

  // filter 2nd liste based on selected variante
  const options = product.options.map((group, idx) => {
    if (idx === 0) {
      return group;
    }

    const values = variants
      .map(v => v.selectedOptions)
      .filter(v => v[0].value === variant.selectedOptions[0].value)
      .map(v => v[idx].value);

    return {
      ...group,
      values
    };
  })

  return (
    <Wrapper>
      <div className="container">
        <Header>
          <div />
          <MIF>
            <div className="item">
              <img
                src={MIFIcon}
                height={17}
                alt="Made in france"
              />
              {intl.formatMessage(trads.madeInFrance)}
            </div>
          </MIF>
        </Header>
        <TwoColumnGrid>
          <GridRight>
            <Gallery
              product={product}
              variant={variant}
              mobileOnly
              variantOnly
              sliderMode
            />
            <StickyBlock>
              <Description
                product={product}
                options={options}
                variant={variant}
                selectOption={selectOption}
                addToCart={addToCart}
                shopOptions={props.shopOptions}
              />
            </StickyBlock>
          </GridRight>
          <GridLeft>
            <Gallery
              product={product}
              variant={variant}
              commonOnly
              mobileOnly
            />
          </GridLeft>
        </TwoColumnGrid>
      </div>
      <div className="whitebg">
        {product.contentBlocks.map((block) => (
          <ContentBlock
            key={block.id}
            {...block}
          />
        ))}
        <div className="container">
          <MoreInfos
            description={product.moreInfos}
            legal={product.legal}
          />
        </div>
      </div>
    </Wrapper>
  );
}

export default ProductPage;