import React from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '../../../components/icons';
import WarningIcon from '../../../icons/Warning.svg';
import HtmlRender from '../../../components/common/Html';

import trads from '../trads';

import {
  Wrapper,
  Title
} from './MoreInfos.styles';

const MoreInfos = ({
  description,
  legal
}) => {
  const intl = useIntl();
  if (!description && !legal) {
    return null;
  }

  let maxWidth = description && legal ? 1440 / 2 : 1440;

  return (
    <Wrapper>
      {description ? (
        <div className="block">
          <Title>
            {intl.formatMessage(trads.productInformations)}
          </Title>
          <HtmlRender
            html={description}
            maxWidth={maxWidth}
            className="content"
          />
        </div>
      ) : null}
      {legal ? (
        <div className="block">
          <Title>
            <Icon
              src={WarningIcon}
              height={22}
            />
            {intl.formatMessage(trads.productWarnings)}
          </Title>
          <HtmlRender
            html={legal}
            maxWidth={maxWidth}
            className="content"
          />
        </div>
      ): null}
      
    </Wrapper>
  )
};

export default MoreInfos;