import styled from 'styled-components';

import { device } from '../../../../utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-family: 'Montserrat';
  display: flex;
  font-size: 20px;
  font-weight: 900;
  font-style: italic;
  color: #111111;
  margin: 20px 0 15px;
  text-transform: uppercase;
  align-items: flex-start;

  .icon {
    margin-right: 15px;
  }

  @media ${device.tablet} {
    margin: 30px 0 20px;
  }

  @media ${device.laptop} and ${device.laptopMDown} {
    font-size: 18px;
  }
`;

export const MenuItem = styled(Title)`
  font-size: 15px;
  margin: 0 0;
  cursor: pointer;
  opacity: .5;
  transition: opacity .3s;

  &:hover, &.active {
    opacity: 1;
  }
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;

  li {
    padding: 0;
    margin-right: 20px;
  }
`;