import { defineMessages } from 'react-intl';

export default defineMessages({
  configuratorTitle: {
    id: 'ecom.configurator.title',
    defaultMessage: 'Trouvez la meilleure huile pour votre moto :',
  },
  noProducts: {
    id: 'ecom.no-products',
    defaultMessage: 'Aucun produit'
  },
  viewMore: {
    id: 'ecom.view-more',
    defaultMessage: 'Voir plus'
  },
  search: {
    id: 'ecom.search',
    defaultMessage: 'Rechercher'
  },
  sortNatural: {
    id: 'ecom.sort-natural',
    defaultMessage: 'Meilleures ventes',
  },
  sortPrice: {
    id: 'ecom.sort-price',
    defaultMessage: 'Prix croissant',
  },
  sortPriceDown: {
    id: 'ecom.sort-price-down',
    defaultMessage: 'Prix décroissant',
  },
  madeInFrance: {
    id: 'ecom.made-in-France',
    defaultMessage: 'Fabriqué en France'
  },
  buy: {
    id: 'ecom.buy',
    defaultMessage: 'Acheter'
  },
  addToCart: {
    id: 'ecom.add-to-cart',
    defaultMessage: 'Ajouter au panier'
  },
  outOfStock: {
    id: 'ecom.out-of-stock',
    defaultMessage: 'Stocks épuisés'
  },
  productInformations: {
    id: 'ecom.product-informations',
    defaultMessage: 'Informations détaillées'
  },
  productWarnings: {
    id: 'ecom.product-warnings',
    defaultMessage: 'Avertissement, précautions d’emploi'
  },
  gotoProductRange: {
    id: 'ecom.goto-product-range',
    defaultMessage: 'Voir toute la gamme'
  },
  feedbackTitle: {
    id: 'ecom.feedback-title',
    defaultMessage: 'LES AVIS DE LA COMMUNAUTÉ'
  },
  feedbackCommentsTab: {
    id: 'ecom.feedback-comments-tab',
    defaultMessage: 'AVIS DÉTAILLÉS'
  },
  feedbackVideosTab: {
    id: 'ecom.feedback-videos-tab',
    defaultMessage: 'AVIS Vidéos'
  },
  feedbackVideosNothing: {
    id: 'ecom.feedback-videos-nothing',
    defaultMessage: 'Ce produit n\'a pas encore d\'avis video'
  }
});
