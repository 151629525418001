import React from 'react';

import AddToCart from './AddToCart';
import Features from '../../../components/eshop/Features';

import {
  Wrapper,
  Title,
  Category,
  Informations,
  DisplayOrder,
} from './Description.styles';

const Description = ({
  product,
  options,
  addToCart,
  variant,
  selectOption,
}) => {
  return (
    <Wrapper>
      <h1 className="nostyle">
        <Title className="bigtitle">{product.title}</Title>
        <Category>{product.productType}</Category>
      </h1>

      <DisplayOrder>
        <AddToCart
          options={options}
          variant={variant}
          addToCart={addToCart}
          selectOption={selectOption}
        />
        <Informations>
          <div className="description"
            dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
          />
          <Features product={product} />
        </Informations>
      </DisplayOrder>
    </Wrapper>
  )
};

export default Description;