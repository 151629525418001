import styled from 'styled-components';

// import { device } from '../../../../utils';

export const Wrapper = styled.div`
  text-align: center;

  .teester {
    position: relative;
    width: 100%;
    max-width: 450px;
    height: 255px;
    margin-left: auto;
    margin-right: auto;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  p {
    font-weight: 500;
  }
`;
