import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.div`
  position: relative;
  padding: 30px 0px;
  /* background-color: #fafafa; */
  /* margin: 30px 0; */

  @media ${device.laptop} {
    padding: 50px 30px;
  }
`;

export const Subhead = styled.h2`
  font-family: 'Montserrat';
  margin-bottom: 20px;
  font-weight: bold;
  font-style: italic;
  font-size: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .media, .text {
    flex: 1;
  }

  .media {
    margin-bottom: 30px;
  }

  .gatsby-image-wrapper {
    background-color: #fafafa;
  }

  .content {
    /* flex: 1; */
    p, a {
      @media ${device.laptopDown} {
        font-size: 14px;
      }
    }
  }

  .video {
    position: relative;
    padding-bottom: 75%;
    width: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;

    .media {
      margin-right: 5%;
      margin-bottom: 0;
    }

    .text {
      margin-left: 5%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &.reverse {
      flex-direction: row-reverse;

      .media {
        margin-right: 0;
        margin-left: 5%;
      }

      .text {
        margin-left: 0%;
        margin-right: 5%;
      }
    }
  }
`;

export const Title = styled.h3`
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  margin: 0 0px 10px 0;

  @media ${device.laptop} {
    font-size: 35px;
    margin: 0 0px 25px 0;
  }
`;
