import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { Swipeable } from 'react-swipeable';

// import { Icon } from '../icons';
// import NextIcon from '../../icons/Next.svg';
// import { isClient } from '../../utils';

import {
  Wrapper,
} from './Slider.styles';

const Slider = ({
  children
}) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const slidesCount = children.length;

  const nextSlide = useCallback(() => {
    setSlideIndex(c => c >= slidesCount - 1 ? 0 : c + 1);
  }, [slidesCount]);

  const prevSlide = useCallback(() => {
    setSlideIndex(c => c <= 0 ? slidesCount - 1 : c - 1);
  }, [slidesCount]);

  const isSlider = children.length > 1;

  return (
    <Wrapper>
      {children.map((e, idx) => (
        <Swipeable
          className={cn('slide', {
            first: idx === 0,
            show: slideIndex === idx
          })}
          onSwipedLeft={nextSlide}
          onSwipedRight={prevSlide}
          key={e.key || idx}
        >
          {e}
        </Swipeable>
      ))}
      {isSlider && (
        <div className="dots">
          {children.map((e, idx) => (
            <button
              className={cn('dot clear', {
                show: slideIndex === idx
              })}
              key={e.key || idx}
              onClick={() => setSlideIndex(idx)}
              aria-label={`Slide ${idx + 1}`}
            >
              <div className="circle" />
            </button>
          ))}
        </div>
      )}
      {/* {isSlider && isClient ? (
        <div className="pager">
          <button
            onClick={prevSlide}
            className="clear prev"
            aria-label="Previous"
          >
            <Icon src={NextIcon} />
          </button>
          <button
            onClick={nextSlide}
            className="clear"
            aria-label="Next"
          >
            <Icon src={NextIcon} />
          </button>
        </div>
      ) : null} */}
    </Wrapper>
  );
};

export default Slider;
