import React from 'react';
import cs from 'classnames';
import styled from 'styled-components';

const currencyFormatter = new Intl.NumberFormat("fr", { style: "currency", currency: "EUR" });
const formatPrice = (rawPrice, quantity = 1) => {
  const price = parseFloat(rawPrice, 10);

  if (isNaN(price)) {
    return '-';
  }

  return currencyFormatter.format(price * quantity).replace(' €', '€');
}
const Wrapper = styled.div`
  font-family: 'Montserrat';
  padding-top: 10px;
  margin-top: auto;
  font-weight: 600;
  font-style: italic;
  font-size: 17px;

  .dashed {
    color: #c80f05;
    text-decoration: line-through;
    margin-right: 15px;
  }
`;

const Price = ({
  price,
  originalPrice,
  quantity = 1,
  stacked = false,
}) => {
  const onSale = originalPrice && price !== originalPrice;
  return (
    <Wrapper
      className={cs('price', {
        'on-sale': onSale,
      })}
    >
      {onSale ? <span className="dashed">{formatPrice(originalPrice, quantity)}</span> : null}
      <span>{price === 0 ? 'Offert' : formatPrice(price, quantity)}</span>
    </Wrapper>
  );
}

export default Price;