import React from 'react';
import Image from 'gatsby-image';
import cn from 'classnames';

import HtmlRender from '../../../components/common/Html';
import { youtubeParser } from '../../../utils';

import {
  Wrapper,
  Title,
  Container,
  Subhead
} from './ContentBlock.styles';

const ContentBlock = ({
  subhead,
  title,
  image,
  isTextLeft = false,
  video,
  text,
}) => {
  if (!title && !text && (!image || !video)) {
    return null;
  }
  const youtubeId = video ? youtubeParser(video) : undefined;

  return (
    <Wrapper>
      {!!subhead && (
        <Subhead
          className="container"
        >
          {subhead}
        </Subhead>
      )}
      <Container
        className={cn('container', {
          reverse: isTextLeft
        })}
      >
        <div className="media">
          {(!youtubeId && image) && (
            <Image
              fluid={image}
            />
          )}
          {youtubeId && (
            <div className="video">
              <iframe
                title="Youtube video"
                src={`https://www.youtube-nocookie.com/embed/${youtubeId}?controls=0&modestbranding=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          )}
        </div>
        <div className="text">
          <Title>
            {title}
          </Title>
          <HtmlRender
            html={text}
            className="content"
          />
        </div>
      </Container>
    </Wrapper>
  )
};

export default ContentBlock;