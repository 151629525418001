
export const pushEvent = (event) => {
  if (typeof window !== 'object' || !window.dataLayer?.length) {
    return;
  }
  window.dataLayer.push(event);
}

export const trackAddToCart = ({
  name,
  sku,
  price,
  category,
  variant,
  quantity,
}) => {
  pushEvent({
    'event': 'addToCart',
    'productName': name,
    'ecommerce': {
      'currencyCode': 'EUR',
      'add': {
        'products': [{
          'name': name,
          'id': sku,
          'price': price,
          'brand': 'Ipone',
          'category': category,
          'variant': variant,
          'quantity': quantity
        }]
      },
      'click': undefined,
      'detail': undefined,
      'impressions': undefined
    }
  });
};

export const trackRemoveFromCart = ({
  name,
  sku,
  price,
  category,
  variant,
  quantity,
}) => {
  pushEvent({
    'event': 'removeFromCart',
    'productName': name,
    'ecommerce': {
      'remove': {
        'products': [{
          'name': name,
          'id': sku,
          'price': price,
          'brand': 'Ipone',
          'category': category,
          'variant': variant,
          'quantity': quantity
        }]
      },
      'click': undefined,
      'detail': undefined,
      'impressions': undefined
    }
  });
};

export const trackProductList = (products = []) => {
  pushEvent({
    'ecommerce': {
      'currencyCode': 'EUR',
      'click': undefined,
      'detail': undefined,
      'add': undefined,
      'remove': undefined,
      'impressions': products.map((p, idx) => ({
        ...p,
        brand: 'Ipone',
        id: p.sku,
        position: p.position || idx + 1
      })).slice(0, 15)
    }
  });
};

export const trackProductDetail = ({
  name,
  variants = [],
  others = []
}) => {
  pushEvent({
    'productName': name,
    'ecommerce': {
      'currencyCode': 'EUR',
      'click': undefined,
      'add': undefined,
      'remove': undefined,
      'detail': {
        'products':  variants.map((p, idx) => ({
          ...p,
          brand: 'Ipone',
          id: p.sku,
          position: idx + 1
        }))
      },
      'impressions': others.map((p, idx) => ({
        ...p,
        brand: 'Ipone',
        id: p.sku,
        position: idx + 1
      }))
    }
  });
};

export const trackProductClick = async ({
  name,
  sku,
  price,
  category,
  variant,
  list,
  position
}) => {
  return new Promise((resolve) => {
    pushEvent({
      'event': 'productClick',
      'productName': name,
      'ecommerce': {
        'currencyCode': 'EUR',
        'click': {
          'actionField': {
            'list': list
          },
          'products': [{
            'name': name,
            'id': sku,
            'price': price,
            'brand': 'Ipone',
            'category': category,
            'variant': variant,
            position
          }]
        },
        'impressions': undefined,
        'detail': undefined,
        'add': undefined,
        'remove': undefined,
      },
      'eventCallback': resolve
    });
  });
};

export const resetEcomTracking = () => {
  pushEvent({
    'ecommerce': undefined
  });
}