import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.div`
  padding: 20px 0;
  background: #FFF;

  @media ${device.tablet} {
    padding: 40px 0;
  }

  .reinsurance {
    position: relative;
    background-color: #fafafa;
    border-radius: 30px;

    grid-column: span 2;


    .bigtitle {
      font-size: 18px;
      line-height: 1em;
      margin-left: 10px;
    }

    .content {
      padding: 20px;
      padding-right: 10px;
      font-size: 14px;
      display: flex;
      flex-direction: column;

      ul {
        flex: 1;
        margin: 0;
        padding: 15px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        li {
          padding: 0;
          margin: 10px 0;
          list-style: none;
          display: flex;
          align-items: center;

          .icon-col {
            width: 35px;
            margin-right: 10px;
            text-align: center;
            flex-shrink: 0;
          }
        }
      }
    }

    @media ${device.tablet} {
      border-radius: 30px 0 0 30px;
      grid-column: auto;
      height: 0;
      padding-bottom: 100%;  /* ensure square box */

      .content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        ul {
          padding: 10% 0;
        }
      }
    }

    @media ${device.laptop} and ${device.laptopMDown} {
      height: 100%;
      padding-bottom: 0;
    }

    @media ${device.laptopM} and (max-width: 1335px) {
      .content {
        ul {
          padding: 0% 0;
          li {
            margin: 5px 0;
          }
        }
      }
    }
  }
  
`;

export const Title = styled.h2`
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 900;
  font-style: italic;
  color: #111111;
  margin: 0 0 20px 0;

  @media ${device.tablet} {
    margin: 0 0 30px 0;
  }
`;

export const Grid = styled.div`
 
`;