import React from 'react';
import Img from 'gatsby-image';
import { useIntl } from 'react-intl';

import Price from './Price';

import {
  getProductUrl,
  ShopifyFluid,
  getLocaleSettings,
} from '../../utils';

import { trackProductClick } from '../../utils/gtm';

import trads from './trads';

import {
  Wrapper,
  Cover,
  Text,
  OutOfStock
} from './ProductItem.styles';

const imgResponsive = '(max-width: 1024px) 40vw, 20vw';

const doTrack = async (props) => {
  console.log('track', props);
  trackProductClick({
    name: props.title,
    sku: props.defaultVariant?.sku,
    price: props.defaultVariant?.price,
    category: props.productType,
    list: props.trackList,
    variant: props.defaultVariant?.title,
    position: props.trackPosition
  });
};

const ProductItem = (props) => {
  const intl = useIntl();
  const settings = getLocaleSettings(intl.locale);
  const { thumbnail } = props;
  const imageUrl = thumbnail?.originalSrc;
  const imageInfos = thumbnail?.localThumb?.childImageSharp?.fixed;
  const imageFixed = ShopifyFluid(imageUrl, { maxWidth: 300 }, {
    ...imageInfos,
    aspectRatio: imageInfos ? imageInfos?.width / imageInfos?.height : 1,
    width: undefined,
    height: undefined
  });
  imageFixed.sizes = imgResponsive;

  let url = getProductUrl(props.handle, intl.locale, props.defaultVariant);

  if (props.urlParams) {
    const params = Object.keys(props.urlParams)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(props.urlParams[k])}`);
    url = `${url}?${params.join('&')}`;
  }

  return (
    <Wrapper
      to={url}
      onClick={() => {
        doTrack(props);
      }}
    >
      <Cover>
        {imageFixed ? (
          <Img
            fluid={imageFixed}
            alt={thumbnail?.altText || props.title}
            loading="lazy"
            className="product-image"
          />
        ) : <div className="gatsby-image-wrapper" />}
      </Cover>
      {!props.availableForSale ? (
        <OutOfStock>{intl.formatMessage(trads.outOfStock)}</OutOfStock>
      ) : null}
      <Text>
        <h3>{props.title}</h3>
        <div className="category">{props.productType}</div>
        {settings.shop ? (
          <Price
            price={props.defaultVariant?.price}
            originalPrice={props.defaultVariant?.compareAtPrice}
          />
        ): null}
      </Text>
    </Wrapper>
  );
}

export default ProductItem;