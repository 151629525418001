import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'react-intl';

import SEO from '../../components/seo';
import ProductScreen from '../../screens/eshop/product';

import { Product, regroup } from '../../utils';
import { trackProductDetail } from '../../utils/gtm';

const ProductTemplate = ({ data, pageContext }) => {
  const intl = useIntl();
  // console.log('before', data.shopifyProduct);
  const { viscosity } = pageContext;

  const product = Product(data.shopifyProduct, intl.locale);
  const ogImage = product.thumbnail?.originalSrc;

  if (viscosity) {
    product.defaultVariant = product.variants.find(v => v.viscosity === viscosity);
  }

  trackProductDetail({
    name: product.title,
    variants: [{
      sku: product.defaultVariant?.sku,
      name: product.title,
      price: product.defaultVariant?.price,
      category: product.productType,
      variant: product.defaultVariant?.title,
    }],
    others: product.recommendations.map(p => ({
      sku: p.defaultVariant?.sku,
      name: p.title,
      price: p.defaultVariant?.price,
      category: p.productType,
      variant: p.defaultVariant?.title,
      list: 'Similar Products'
    }))
  });


  const shopOptions = regroup(data.menu.nodes.slice().shift()?.data || {});
  // console.log('after', product);

  return (
    <>
      <SEO
        title={`${product.title} ${viscosity || ''}`.trim()}
        description={product.description}
        image={ogImage}
        preconnect={[
          'https://cdn.shopify.com',
          'https://images.accentuate.io'
        ]}
      />
      <ProductScreen
        product={product}
        shopOptions={shopOptions?.cart}
      />
    </>
  );
}

export const query = graphql`
  query EshopProduct(
    $shopifyId: String!,
    $locale: String!,
    $localeGlob: String!
  ) {
    menu: allPrismicMenu(
      filter: { lang: { glob: $localeGlob } }
    ) {
      nodes {
        data {
          cart_banner
          cart_stock_label
          cart_ship_details
          cart_ship_limit_hour
          cart_holidays_start
          cart_holidays_message
          cart_holidays_end
        }
      }
    }
    shopifyProduct(
      shopifyId: { eq: $shopifyId },
      locale: { eq: $locale }
    ) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      thumbnail {
        originalSrc
      }
      options {
        id
        name
        values
        isViscosity
      }
      collections {
        title
        handle
        customfields {
          isRootCategory
          # isProductRange
        }
      }
      customfields {
        # moreImages {
        #   image {
        #     alt
        #     aspect_ratio
        #     cloudinary_src
        #   }
        #   text
        # }
        contentBlock {
          text
          textLeft
          subhead
          title
          video
          image {
            cloudinary_src
            aspect_ratio
            alt
          }
        }
        moreInfos
        legal
        features {
          icon {
            cloudinary_src
            aspect_ratio
            alt
          }
          text
        }
      }
      defaultVariant {
        title
        sku
        price
        compareAtPrice
        viscosity
      }
      variants {
        id
        sku
        title
        price
        compareAtPrice
        availableForSale
        shopifyId
        viscosity
        selectedOptions {
          name
          value
        }
        image {
          id
          originalSrc
          altText
          # localFile: localThumb {
          #   childImageSharp {
          #     fluid(maxWidth: 5, srcSetBreakpoints: [5]) {
          #       aspectRatio
          #       tracedSVG
          #     }
          #   }
          # }
        }
        customfields {
          moreImages {
            image {
              alt
              aspect_ratio
              cloudinary_src
              id
            }
            text
          }
        }
      }
      recommendations {
        ...ProductItem
      }
    }
  }
`
export default ProductTemplate;