import styled from 'styled-components';
import { Link } from 'gatsby';
import { device } from '../../utils';

export const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  padding-bottom: 0;
  background-color: #fafafa;
  text-align: center;

  transition: background-color 400ms ease-out;

  .bigtitle {
    margin: 0 auto;
    line-height: 1em;
    font-size: 20px;
  }

  .category {
    font-size: 13px;
    font-weight: normal;
    text-align: center;
    color: #111111;
    line-height: 1.3em;
  }

  .more {
    margin-top: auto;
  }

  .image {
    margin-top: 30px;
    width: 100%;
  }

  &:hover {
    background-color: #E9E9E9;
  }

  @media ${device.laptop} {
    padding: 30px;
    padding-bottom: 0;

    .bigtitle {
      max-width: 70%;
      font-size: 30px;
    }

    .category {
      font-size: 14px;
    }
  }
`;