import { defineMessages } from 'react-intl';

export default defineMessages({
  viewMore: {
    id: 'ecom.widgets.view-more',
    defaultMessage: 'En savoir plus',
  },
  outOfStock: {
    id: 'ecom.widgets.out-of-stock',
    defaultMessage: 'Stocks épuisés'
  },
  recommendation: {
    id: 'ecom.widgets.recommendation',
    defaultMessage: 'VOUS AIMEREZ AUSSI…'
  },
  garantee: {
    id: 'ecom.widgets.garantee',
    defaultMessage: 'Garanties Ipone.com'
  },
  freeShipping: {
    id: 'ecom.widgets.free-shipping',
    defaultMessage: 'Livraison offerte dès 30€'
  },
  exchange: {
    id: 'ecom.widgets.exchange',
    defaultMessage: 'Échange garanti'
  },
  official: {
    id: 'ecom.widgets.official',
    defaultMessage: 'Boutique officielle'
  },
  secure: {
    id: 'ecom.widgets.secure',
    defaultMessage: 'Paiement sécurisé'
  }
});
