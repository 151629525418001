import styled from 'styled-components';
import { Link } from 'gatsby';

import { device } from '../../utils';

export const Cover = styled.div`
  overflow: hidden;
  text-align: center;
  background-color: #fafafa;

  transition: background-color 400ms ease-out;

  .gatsby-image-wrapper {
    width: 100%!important;
    height: 0!important;
    padding-bottom: 100%;
    * {
      width: 100%!important;
    }
  }
`;

export const OutOfStock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #8B8C91;
  text-align: center;
  color: #FFF;
  font-weight: bold;
  padding: 6px 10px;
  font-size: 10px;

  @media ${device.tablet} {
    font-size: 15px;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 0px 20px 0px;

  h3 {
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    margin: 0 0 3px;
    text-transform: uppercase;
  }

  .category {
    color: #6f7175;
    font-weight: normal;
    font-size: 12px;
  }

  .price {
    font-family: 'Montserrat';
    padding-top: 10px;
    margin-top: auto;
    font-style: italic;
    font-size: 15px;
  }

  @media ${device.tablet} {
    h3, .category {
      font-size: 15px;
    }

    h3 {
      margin: 0 0 5px;
    }

    .price {
      font-size: 20px;
    }
  }
`;

export const Wrapper = styled(Link)`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;

  &:hover {
    ${Cover} {
      background-color: #E9E9E9;
    }
  }
`;