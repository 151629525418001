import styled from 'styled-components';

import { device } from '../../utils';

export const Wrapper = styled.nav`
  margin-top: 20px;
  display: none;

  @media ${device.tablet} {
    display: block;
  }

  .inner {
    display: inline-block;
    background: #000;
    transform: skew(-15deg);
  }

  ul {
    margin: 0;
    display: inline-block;
    width: auto;
    list-style: none;
    padding: 5px 15px;
    transform: skew(15deg);

    li {
      padding: 0;
      display: inline-block;

      &.home {
        margin-right: 10px;

        .item {
          vertical-align: middle;
        }
      }

      .item {
        display: inline-block;
        color: #FFF;
        text-transform: uppercase;
        font-size: 12px;
      }

      .sep {
        filter: invert(1);
        margin: 0 7px;
      }

      &:last-child {
        .sep {
          display: none;
        }

        .item {
          font-weight: 500;
        }
      }
    }
  }
`;