import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.div`
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: dense;
  align-items: center;
  width: 100%;
  grid-gap: 20px 10px;
  margin: 0 0 20px 0;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .option {
    display: flex;
    flex-direction: column;
    background: #fafafa;
    border-radius: 30px;
    padding: 12px 30px;

    .label {
      font-size: 10px;
      text-align: left;
      color: #494a4d;
      margin-bottom: 3px;
    }
  }

  .select {
    outline: none;
    border: 0;
    font-weight: 500;
    font-size: 15px;
    min-height: 0;
    padding-left: 0;
    min-width: 35px;

    &.one-value {
      padding: 2px 0;
    }

    &, * {
      outline: none;
    }

    .react-dropdown-select-dropdown-handle {
      margin: 0;
    }

    .react-dropdown-select-dropdown {
      max-width: 100px;

      @media ${device.tablet} {
        max-width: none;
      }
    }

    &, &:hover, &:focus, &:active {
      border: 0;
      outline: none;
      box-shadow: none!important;
    }

    input {
      display: none;
    }
  }
`;

export const PriceContainer = styled.div`
  display: block;
  font-size: 20px;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Montserrat';
  font-style: italic;
  margin-left: 30px;

  @media ${device.tablet} {
    grid-column: auto;
  }

  .price {
    font-size: 22px;

    &.on-sale {
      margin-left: -30px;

      .dashed {
        font-size: 15px;
        margin-right: 10px;
      }
    }
  }
`;

export const AddToCartButton = styled.button`
  grid-column: span 2;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #c80f05;
  color: #FFF;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  border-radius: 30px;
  padding: 15px 15px;
  appearance: none;
  border: 0;
  transition: background-color 150ms;

  @media ${device.tablet} {
    font-size: 18px;
    padding: 15px 40px;
    grid-column: span 2;
  }

  .icon {
    stroke: #FFF;
    margin-left: 5px;
  }

  &:hover {
    background-color: #b30e05;
  }

  &:active {
    background-color: #a80c03;
  }

  &[disabled] {
    background-color: #8B8C91;
    cursor: default;
    font-weight: bold;
  }
`;