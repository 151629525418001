import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-dropdown-select';

import { Icon } from '../../../components/icons';
import { Price, Quantity } from '../../../components/eshop';
import CartIcon from '../../../icons/Cart.svg';
import { getLocaleSettings } from '../../../utils';

import trads from '../trads';

import {
  Wrapper,
  PriceContainer,
  AddToCartButton
} from './AddToCart.styles';

const AddToCart = ({
  options = [],
  variant,
  addToCart,
  selectOption,
}) => {
  const intl = useIntl();
  const settings = getLocaleSettings(intl.locale);
  const [quantity, setQuantity] = useState(1);
  const state = variant.selectedOptions.reduce((acc, opt) => ({
    ...acc,
    [opt.name]: opt.value
  }), {});

  const selectVariant = React.useCallback((option, value) => {
    selectOption && selectOption(option, value);
  }, [selectOption]);
  
  const addToCartWrap = React.useCallback(() => {
    addToCart && addToCart(variant, quantity);
  }, [addToCart, quantity, variant]);

  return (
    <Wrapper>
        {[...options, null, null].splice(0, 2).map((option, idx) => {
          if (!option || option.name === 'Title') {
            return <div
              key={idx}
            />;
          }

          return (
            <div
              key={option.id}
              className="option"
            >
              <div
                className="label"
              >
                {option.name}
              </div>
              {option.values?.length > 1 && (
                <Select
                  className="select"
                  options={option.values.map(v => ({
                    label: v,
                    value: v
                  }))}
                  dropdownPosition="auto"
                  color="#c80f05"
                  values={[{ label: state[option.name], value: state[option.name]}]}
                  onChange={([selected]) => selectVariant(option, selected.value)}
                  searchable={false}
                />
              )}
              {option.values?.length === 1 && (
                <div className="select one-value">{option.values[0]}</div>
              )}
            </div>
          );
        })}
        
    </Wrapper>
  )
};

export default AddToCart;