import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* padding-top: 10px; */
  position: relative;
  font-size: 14px;
  background: #FFF;

  .content {
    flex: 1;
    padding: 20px 30px;
    background-color: #fafafa;

    p, a {
      font-size: 14px;
    }
  }

  .block {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  @media ${device.laptop} {
    flex-direction: row;

    .block {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export const Title = styled.h2`
  font-family: 'Montserrat';
  display: flex;
  font-size: 20px;
  font-weight: 900;
  font-style: italic;
  color: #111111;
  margin: 20px 0;
  text-transform: uppercase;
  align-items: flex-start;

  .icon {
    margin-right: 15px;
  }

  @media ${device.tablet} {
    margin: 30px 0;
  }

  @media ${device.laptop} and ${device.laptopMDown} {
    font-size: 18px;
  }
`;