import styled from 'styled-components';

import { device } from '../../../utils';

export const Wrapper = styled.section`
  width: 100%;
  background-color: #FAFAFA;

  .whitebg {
    width: 100%;
    background-color: #FFF;
    padding-top: 0px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const TwoColumnGrid = styled.div`
  display: grid;

  /* @media ${device.laptop} {
    display: grid;
    grid-template-columns: 1fr 3rem 1fr;
    grid-template-areas: "left . right";
    margin-bottom: 60px;
  }

  @media ${device.laptopM} {
    display: grid;
    grid-template-columns: 1fr 7rem 1fr;
    grid-template-areas: "left . right";
    margin-bottom: 60px;
  } */
`;

export const GridLeft = styled.div`
   @media ${device.tablet} {
    margin: 0;
  }

  /* @media ${device.laptop} {
    grid-area: left;
  } */
`;

export const GridRight = styled.div`
  position: relative;

  /* @media ${device.laptop} {
    grid-area: right;
    min-width: 550px;
    margin-right: -20px;
  } */
`;

export const StickyBlock = styled.div`
  @media ${device.tablet} {
    padding-top: 30px;
  }

  /* @media ${device.laptop} {
    position: sticky;
    top: var(--header-height);
  } */
`;

export const MIF = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  padding-right: 20px;
  margin-top: 20px;

  @media ${device.tabletDown} {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding-right: 0;
    margin-left: auto;
    justify-content: space-between;

    font-size: 12px;

    img, .icon {
      height: 15px!important;
    }
  }

  img, .icon {
    margin-right: 5px;
  }

  img {
    margin-left: 20px;
  }

  .item {
    display: flex;
    align-items: center;
  }
`;