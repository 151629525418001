import React, { useRef } from 'react';
import { windowScrollTo } from 'seamless-scroll-polyfill';
import csx from 'classnames';
import { useWindowScroll } from 'react-use';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import Carousel from '../../../components/common/Slider';
import { useBreakpoint } from '../../../utils';

import {
  Wrapper,
  ImagesWrapper,
  ThumbnailsSticky,
  Thumbnails,
  Img
} from './Gallery.styles';


const ImagesContainer = ({ isSlider, children }) => {
  if (!isSlider || children.length <= 1) {
    return (
      <ImagesWrapper>
        {children}
      </ImagesWrapper>
    );
  }

  return (
    <ImagesWrapper>
      <Carousel>
        {children[0]}
      </Carousel>
    </ImagesWrapper>
  )
}

const StickThumbs = ({ container, images, defaultAlt }) => {
  const ref = useRef();
  const { y } = useWindowScroll();
  const elemTop = container?.current?.offsetTop || -1;
  const elemHeight = container?.current?.offsetHeight || -1;
  const boxHeight = ref?.current?.offsetHeight || -1;
  const boxTop = 150;

  let top = boxTop;

  if (elemHeight > -1 && y + boxHeight + boxTop > elemTop + elemHeight) {
    top = elemHeight + elemTop - y - boxHeight;
  }

  return (
    <ThumbnailsSticky
      ref={ref}
      style={{
        top
      }}
    >
      <Thumbnails>
        {images.map(image => (
          <li
            key={image.id}
            onClick={() => {
              const target = document.getElementById(`productImage-${image.id}-desktop`);
              if (!target) {
                return;
              }
              windowScrollTo(
                {
                  duration: 700,
                  behavior: 'smooth',
                  top: target.offsetTop,
                }
              );
            }}
            role="presentation"
          >
            <Img
              fluid={image.thumbnail}
              alt={image.altText || defaultAlt}
              className="product-image"
              loading="lazy"
            />
          </li>
        ))}
      </Thumbnails>
    </ThumbnailsSticky>
  )
}


const Gallery = ({
  product,
  variant,
  variantOnly,
  commonOnly,
  mobileOnly,
  sliderMode,
  hideMobile,
  teesterVideoId
}) => {
  // const device = useBreakpoint();
  const device = 'tablet';
  const ref = React.useRef(null);

  const images = [
    ...(commonOnly ? [] : variant.images),
    ...(variantOnly ? [] : product.commonImages.map(i => ({
      ...i,
      isCommon: true,
    })))
  ].filter(i => i.fluid);

  if (!images.length) {
    return null;
  }

  return (
    <Wrapper
      ref={ref}
      className={csx({
        'mobile-mode': true,
        // 'desktop-mode': hideMobile,
        'slider-mode': true
      })}
    >
      {hideMobile && device !== 'ssr' && device !== 'mobile' && device !== 'tablet' ? (
        <StickThumbs
          images={images}
          defaultAlt={product.title}
          container={ref}
        />
      ) : null}

      <ImagesContainer isSlider={sliderMode}>
        {images.map((image, idx) => (
          <div
            key={image.id}
            id={`productImage-${image.id}-${hideMobile ? 'desktop' : 'mobile'}`}
            className="slide-container"
          >
            <Zoom
              wrapStyle={{
                width: '100%'
              }}
            >
              <Img
                fluid={image.fluid}
                alt={image.altText || product.title}
                className={csx('product-image', {
                  common: image.isCommon,
                  'in-slider': sliderMode
                })}
                loading={idx === 0 ? 'eager' : 'lazy'}
                imgStyle={{
                  objectFit: 'contain'
                }}
              />
            </Zoom>
            {!sliderMode && image.altText ? <legend>{image.altText}</legend> : null}
          </div>
        ))}
        {teesterVideoId ? (
          <div className="teester">
            <iframe
              title="Teester"
              src={`https://player.teester.com/#id=${teesterVideoId}`}
              frameBorder="0"
              allowFullScreen  
            />
          </div>
        ) : undefined}
      </ImagesContainer>
        
    </Wrapper>
  )
};

export default Gallery;